// Сворачивание/разворачивание таблиц

var collapseTable = function () {
    var parent = this.parentElement;
    var tableBlock = this.nextElementSibling,
        imgHeight = window.innerWidth >= 768 ?
            this.nextElementSibling.querySelector('.table-img--desktop').clientHeight :
            this.nextElementSibling.querySelector('.table-img--mobile').clientHeight;

    if (parent.classList.contains('table-collapsed')) {
        this.style.transform = 'rotate(180deg)';
        parent.classList.remove('table-collapsed');
        tableBlock.style.height = imgHeight;
        return false;
    }
    parent.classList.add('table-collapsed');
    this.style.transform = 'rotate(0deg)';
    return false;
};
var collapseTableHeader = function () {
    var parent = this.parentElement.parentElement.parentElement;
    var tableBlock = parent.querySelector('.al-size__table'),
        imgHeight = window.innerWidth >= 768 ?
            parent.querySelector('.table-img--desktop').clientHeight :
            parent.querySelector('.table-img--mobile').clientHeight;

    if (parent.classList.contains('table-collapsed')) {
        parent.classList.remove('table-collapsed');
        tableBlock.style.height = imgHeight;
        return false;
    }
    parent.classList.add('table-collapsed');
    return false;
};

document.querySelectorAll('.al-size__toggle').forEach(function (element) {
    element.onclick = collapseTable;
});

document.querySelectorAll('.al-size__table').forEach(function (elem) {
    if (elem.querySelector('a') !== null) {
        elem.querySelector('a').onclick = function () {
            var topPosition = this.parentElement
                .parentElement.clientHeight;
            this.parentElement
                .parentElement
                .parentElement.classList.add('table-collapsed');
            this.parentElement
                .parentElement
                .previousElementSibling.style.transform = 'rotate(0deg)';
            window.scrollBy(0, -topPosition);
        }
    }
});

document.querySelectorAll('.al-size__table-block').forEach(function (elem) {
    elem.querySelector('h2').onclick = collapseTableHeader;
});




// Смена видосов

var changeVideo = function () {
    var thisSrc = this.querySelector('img').getAttribute('data-video');

    document.querySelectorAll('.al-install-video__nav-item').forEach(function (elem) {
        if (elem.classList.contains('al-install-video__active')) {
           elem.classList.remove('al-install-video__active');
       }
    });
    this.classList.add('al-install-video__active');
    document.querySelector('.al-install-video__preview').querySelector('iframe').setAttribute('src', thisSrc);
};
  
document.querySelectorAll('.al-install-video__nav-item').forEach(function (elem) {
    if (window.innerWidth >= 768) {
        elem.onclick = changeVideo;
    } else {
        return false;
    }
});
$(document).ready(function () {

    // обновляем капчу у формы
    function udpdateCaptcha(selector) {
        var my_form_holder = selector;
        var data = {
            "ID": "1"
        }
        var url = "/ajax/ajax-update-captcha.php";
        $.ajax({
            url: url,
            type: "POST",
            dataType: "HTML",
            data: data,
        })
            .done(function (data) {
                my_form_holder.find('.js_recaptcha-holder').html(data);
                Recaptchafree.reset();
            })
            .fail(function () {
                console.log('запрос не прошел');
            });
    }

    $('.main-slider').slick({
        arrows: false,
        dots: true
    });

   var settingsSlider = {
        arrows: false,
        dots: true,
        slidesToShow: 6,
        slidesToScroll: 6,
        autoScroll: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 4,

                }
            },
            {
                breakpoint: 576,
                settings: 'unslick',
            },

            ]

    };
   const slider =  $('.js-clients-slider').slick(settingsSlider);

   $(window).on('resize', function() {
       if($(window).width() > 576 && !slider.hasClass('slick-initialized')) {
           $('.js-clients-slider').slick(settingsSlider);
       }
   })

});